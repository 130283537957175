/* Define styles for font size dropdown display for 8px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: '8px';
}

/* Define actual font size styles for 8px */
.ql-snow .ql-size[data-value="8px"] {
  font-size: 8px;
}
  
/* Define styles for font size dropdown display for 9px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: '9px';
}

/* Define actual font size styles for 9px */
.ql-snow .ql-size[data-value="9px"] {
  font-size: 9px;
}
  
/* Define styles for font size dropdown display for 10px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px';
}

/* Define actual font size styles for 10px */
.ql-snow .ql-size[data-value="10px"] {
  font-size: 10px;
}
  
/* Define styles for font size dropdown display for 11px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: '11px';
}

/* Define actual font size styles for 11px */
.ql-snow .ql-size[data-value="11px"] {
  font-size: 11px;
}
  
/* Define styles for font size dropdown display for 12px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: '12px';
}

/* Define actual font size styles for 12px */
.ql-snow .ql-size[data-value="12px"] {
  font-size: 12px;
}
  
/* Define styles for font size dropdown display for 13px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: '13px';
}

/* Define actual font size styles for 13px */
.ql-snow .ql-size[data-value="13px"] {
  font-size: 13px;
}
  
/* Define styles for font size dropdown display for 14px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14px';
}

/* Define actual font size styles for 14px */
.ql-snow .ql-size[data-value="14px"] {
  font-size: 14px;
}
  
/* Define styles for font size dropdown display for 15px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: '15px';
}

/* Define actual font size styles for 15px */
.ql-snow .ql-size[data-value="15px"] {
  font-size: 15px;
}
  
/* Define styles for font size dropdown display for 16px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
}

/* Define actual font size styles for 16px */
.ql-snow .ql-size[data-value="16px"] {
  font-size: 16px;
}
  
/* Define styles for font size dropdown display for 17px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
  content: '17px';
}

/* Define actual font size styles for 17px */
.ql-snow .ql-size[data-value="17px"] {
  font-size: 17px;
}
  
/* Define styles for font size dropdown display for 18px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
}

/* Define actual font size styles for 18px */
.ql-snow .ql-size[data-value="18px"] {
  font-size: 18px;
}
  
/* Define styles for font size dropdown display for 19px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
  content: '19px';
}

/* Define actual font size styles for 19px */
.ql-snow .ql-size[data-value="19px"] {
  font-size: 19px;
}
  
/* Define styles for font size dropdown display for 20px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
}

/* Define actual font size styles for 20px */
.ql-snow .ql-size[data-value="20px"] {
  font-size: 20px;
}
  
/* Define styles for font size dropdown display for 21px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
  content: '21px';
}

/* Define actual font size styles for 21px */
.ql-snow .ql-size[data-value="21px"] {
  font-size: 21px;
}
  
/* Define styles for font size dropdown display for 22px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: '22px';
}

/* Define actual font size styles for 22px */
.ql-snow .ql-size[data-value="22px"] {
  font-size: 22px;
}
  
/* Define styles for font size dropdown display for 23px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
  content: '23px';
}

/* Define actual font size styles for 23px */
.ql-snow .ql-size[data-value="23px"] {
  font-size: 23px;
}
  
/* Define styles for font size dropdown display for 24px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px';
}

/* Define actual font size styles for 24px */
.ql-snow .ql-size[data-value="24px"] {
  font-size: 24px;
}
  
/* Define styles for font size dropdown display for 25px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before {
  content: '25px';
}

/* Define actual font size styles for 25px */
.ql-snow .ql-size[data-value="25px"] {
  font-size: 25px;
}
  
/* Define styles for font size dropdown display for 26px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: '26px';
}

/* Define actual font size styles for 26px */
.ql-snow .ql-size[data-value="26px"] {
  font-size: 26px;
}
  
/* Define styles for font size dropdown display for 27px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before {
  content: '27px';
}

/* Define actual font size styles for 27px */
.ql-snow .ql-size[data-value="27px"] {
  font-size: 27px;
}
  
/* Define styles for font size dropdown display for 28px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: '28px';
}

/* Define actual font size styles for 28px */
.ql-snow .ql-size[data-value="28px"] {
  font-size: 28px;
}
  
/* Define styles for font size dropdown display for 29px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="29px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="29px"]::before {
  content: '29px';
}

/* Define actual font size styles for 29px */
.ql-snow .ql-size[data-value="29px"] {
  font-size: 29px;
}
  
/* Define styles for font size dropdown display for 30px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: '30px';
}

/* Define actual font size styles for 30px */
.ql-snow .ql-size[data-value="30px"] {
  font-size: 30px;
}
  
/* Define styles for font size dropdown display for 31px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="31px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="31px"]::before {
  content: '31px';
}

/* Define actual font size styles for 31px */
.ql-snow .ql-size[data-value="31px"] {
  font-size: 31px;
}
  
/* Define styles for font size dropdown display for 32px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: '32px';
}

/* Define actual font size styles for 32px */
.ql-snow .ql-size[data-value="32px"] {
  font-size: 32px;
}
  
/* Define styles for font size dropdown display for 33px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="33px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="33px"]::before {
  content: '33px';
}

/* Define actual font size styles for 33px */
.ql-snow .ql-size[data-value="33px"] {
  font-size: 33px;
}
  
/* Define styles for font size dropdown display for 34px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="34px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="34px"]::before {
  content: '34px';
}

/* Define actual font size styles for 34px */
.ql-snow .ql-size[data-value="34px"] {
  font-size: 34px;
}
  
/* Define styles for font size dropdown display for 35px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="35px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="35px"]::before {
  content: '35px';
}

/* Define actual font size styles for 35px */
.ql-snow .ql-size[data-value="35px"] {
  font-size: 35px;
}
  
/* Define styles for font size dropdown display for 36px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: '36px';
}

/* Define actual font size styles for 36px */
.ql-snow .ql-size[data-value="36px"] {
  font-size: 36px;
}

/* Define styles for font size dropdown display for 36px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: '48px';
}

/* Define actual font size styles for 36px */
.ql-snow .ql-size[data-value="48px"] {
  font-size: 36px;
}


.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value) !important;
}
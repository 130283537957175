@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Kanit&display=swap');
* {
  --black: #152026;
  --pink: #ff6d6d;
  --gray: #707070;
  --white: #ffffff;
  --purple: #8C64B5;
  font-family: 'Kanit', sans-serif !important;
}

html,
body {
  font-family: 'Kanit', sans-serif;
  color: var(--black);
}